.prescriptions--list {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.prescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.prescription--pdf {
    width: 150px;
    height: 182px;
    left: 292px;
    top: 378px;
    filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.2));
}

.prescription--details {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
    margin-top: 14px;
}