@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"); */
/*
html {
   zoom: 0.7;

  }
  */
body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
    font-family: "Roboto", sans-serif !important;
    /* font-family: "Inter", sans-serif !important; */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black;
    font-weight: 400;
    background-color: #FAFAFA;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: whitesmoke;
}

.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
    background-color: #fafafa;
}

.capitalize {
    text-transform: capitalize;
}

/* triage pdf */

.site-description-item-profile-wrapper {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
    font-size: 12px;
    line-height: 1.66667;
}

p.site-description-item-profile-p {
    display: block;
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1rem;
    line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
    font-size: 14px;
    line-height: 1.66667;
}

.site-description-item-profile-p-label {
    display: inline-block;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.85);
}

#capture .ant-card-body {
    padding: 0.5rem 1.5rem 1.5rem;
}

#capture .ant-divider-horizontal {
    margin: 12px 0;
}

/* @media print {
  #capture .ant-card-body {
    padding: 0.5rem 1.5rem;
    break-after: avoid;
    overflow: hidden !important;
  }
  #capture:last-child {
     page-break-after: avoid;
}

  @page {
    size: A4;
    break-after: avoid;
    break-inside: avoid;
    marks: crop;
      bleed: 1cm;

  }

  @page: right {
    @bottom-right {
      content: counter(page) " of " counter(pages);
    }
  }
  .site-description-item-profile-p {
    break-after: avoid;
  }
} */
.bedCard {
    text-align: center;
}

.bedCardOccupied {
    text-align: center;
}

.bedCardOccupied:hover {
    cursor: pointer;
    text-align: center;
    /* box-shadow: 0px 0px 2px 0px darkgrey; */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.bedTitle {
    font-size: 1.5rem;
    margin: 0.625rem 0rem 0rem;
}

.demographic .ant-input-number,
.comorbidities .ant-input-number {
    width: 200px !important;
}

.demographic .ant-input-number,
.demographic .ant-picker .comorbidities .ant-input-number,
.comorbidities .ant-picker {
    width: 100% !important;
}

/* responsibilities */
.card--responsibility {
    width: 400px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

/* .card--responsibility.done {
 background-color: gray;
} */
.card--responsibility:hover {
    cursor: pointer;
    background-color: #1890ff;
    color: white;
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0,.1) */
}

.card--responsibility:hover .card--responsibility-name {
    font-size: 0.825rem;
    padding-left: 1rem;
    color: white;
}

.card--responsibility:hover .ant-card-meta-title {
    font-size: 1.5rem;
    padding-left: 1rem;
    color: white;
}

.card--responsibility .ant-card-meta-title {
    font-size: 1.5rem;
    padding-left: 1rem;
    color: black;
}

.card--responsibility-name {
    font-size: 0.825rem;
    padding-left: 1rem;
    color: black;
}

/* table, th, td {
  border: 1px solid black;
} */

.invResults .ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: #d0d0d0;
}

.landing--patientList .ant-table-title {
    text-align: right;
}

.landing--patientList .ant-table-content {
    font-size: 1.2rem !important;
}

.landing--patientList .ant-table-cell {
    padding: 10px 16px !important;
}

.demographic label {
    font-size: 1.125rem !important;
    font-weight: 500;
}

/* treatment plan */

.donotadminister.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ff4d4f !important;
}

.donotadminister.ant-radio-button-wrapper-checked {
    background: #ff4d4f !important;
    border-color: #ff4d4f !important;
}

.networkDisconnected {
    filter: saturate(0);
}

.com-stat-card {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-size: 2rem;
}

.com-stat {
    display: flex;
    flex-direction: column-reverse;
}

.com-stat .ant-statistic-title {
    color: black;
    font-size: 1.5rem;
    margin-top: 1.125rem;
    margin-bottom: 0px;
}

.com-stat .ant-statistic-content {
    color: black;
    font-size: 2rem;
}

.com-stat .ant-statistic-content-value-int {
    font-weight: bold;
    font-size: 2.25rem;
}

.criticalSPO2Table {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
}

.ant-layout-content {
    padding: 0rem 0.5rem;
}

.bedOccupancyCard .ant-card-body {
    padding: 0rem;
}

.com-stat-beds.available {
    background: rgba(178, 241, 177, 0.49);
    border-radius: 2px;
    padding: 12px;
    margin: 12px 0px;
}

.com-stat-beds.occupied {
    background: rgba(0, 115, 207, 0.19);
    border-radius: 2px;
    padding: 12px;
    margin: 12px 0px;
}

.com-stat-beds.total {
    background: #eeeeee;
    border-radius: 2px;
    padding: 12px;
    margin: 12px 0px;
}

.com-stat-beds .ant-statistic-title {
    color: black;
    font-size: 0.875rem;
}

bold {
    font-weight: 500;
}

.reportIcon svg {
    width: 64px;
    height: 64px;
}

.patient-page-header-hidden {
    display: none;
}

.ant-page-header.patient-page-header {
    margin: 0px 0px 1rem;
    padding: 16px 4px 16px 4px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.ant-page-header.patient-page-header .ant-page-header-heading-left {
    align-items: baseline;
}

.ant-page-header.patient-page-header .ant-page-header-heading-sub-title {
    color: black;
    font-size: 1rem !important;
}

.ant-page-header.patient-page-header .ant-page-header-heading-extra {
    font-weight: 500;
    font-size: 1rem;
}

.ant-page-header.patient-page-header .ant-page-header-heading {
    align-items: center;
    padding: 0px 18px;
}

.patient-page-header .ant-page-header-back-button:hover {
    /* display: none; */
    color: #1890ff !important;
}

.moderate {
    background-color: #ffa15c !important;
    color: black !important;
}

.moderate .ant-page-header-back-button {
    color: black !important;
}

.moderate .ant-page-header-heading {
    color: black !important;
}

.moderate .ant-page-header-heading-title {
    color: black !important;
    font-size: 1.7rem;
}

.moderate .ant-page-header-heading-sub-title {
    color: black !important;
}

.moderate .ant-page-header-heading-extra {
    color: black !important;
    font-weight: 500;
}

.severe {
    background-color: #ff3d37 !important;
    color: white !important;
}

.severe .ant-page-header-back-button {
    color: white !important;
}

.severe .ant-page-header-heading {
    color: white !important;
}

.severe .ant-page-header-heading-title {
    color: white !important;
    font-size: 1.7rem;
}

.severe .ant-page-header-heading-sub-title {
    color: white !important;
}

.severe .ant-page-header-heading-extra {
    color: white !important;
    font-weight: 500;
}

.normal {
    background-color: #a9a9a9 !important;
    color: black !important;
}

.normal .ant-page-header-back-button {
    color: black !important;
}

.normal .ant-page-header-heading {
    color: black !important;
}

.normal .ant-page-header-heading-title {
    color: black !important;
    font-size: 1.7rem;
}

.normal .ant-page-header-heading-sub-title {
    color: black !important;
}

.normal .ant-page-header-heading-extra {
    color: black !important;
    font-weight: 500;
}

.lightgrayBG .ant-drawer-header,
.lightgrayBG .ant-drawer-footer {
    background: lightgray;
}

.hospitalDetails .ant-input-number {
    width: 100% !important;
}

.hospitalDetails label {
    /* font-size: 0.925rem !important; */
    font-weight: 500;
}

.medHistoryNew {
    padding: 0px 10px 24px !important;
}

.medHistoryNew .ant-form-item-label {
    display: none;
}

.medicationHistoryNew--labels {
    font-weight: 600;
    padding: 12px 10px !important;
}

.medicationHistoryNew--formCol {
    height: 4rem;
}

/* .medHistoryNew .medHistoryNew--row:nth-child(even) {
  background: #CCC
} */
.teal {
    background: teal !important;
    border-color: teal !important;
}

.historyOfSymptoms--form .ant-form-item {
    margin-bottom: 0;
}

.mild {
    background-color: #83f52c !important;
    color: black !important;
}

.mild .ant-page-header-back-button {
    color: black !important;
}

.mild .ant-page-header-heading {
    color: black !important;
}

.mild .ant-page-header-heading-title {
    color: black !important;
    font-size: 1.7rem;
}

.mild .ant-page-header-heading-sub-title {
    color: black !important;
}

.mild .ant-page-header-heading-extra {
    color: black !important;
    font-weight: 500;
}

.index {
    margin: 0rem 0rem 0.5rem;
}

.custom--Checkbox {
    font-size: 1rem;
    color: black;
}

.custom--Checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black;
}

.custom--Checkbox .ant-checkbox-checked::after {
    border: 1px solid black;
}

.custom--Checkbox .ant-checkbox-inner {
    transform: scale(1.2) !important;
    border: 1px solid #000 !important;
}

.index--label {
    font-weight: 600;
    font-size: 2rem;
    text-transform: capitalize;
    /* background-color: darkgray;
    padding: 0.4rem 1rem; */
}

.ql-editor {
    min-height: 200px !important;
    color: black;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
    border-color: black !important;
}

.ant-checkbox-wrapper {
    color: black !important;
}

.ant-menu-dark .ant-menu-item-disabled {
    color: rgba(255, 255, 255, 0) !important;
    opacity: 1 !important;
}

.userResponsibilities {
    /* box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important; */
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    background: #FFFFFF !important;
    border: 1px solid #00B466 !important;
    border-radius: 4px !important;
}

.userResponsibilities .resp {
    padding: 16px 32px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #231F20;
    border-bottom: 1px solid rgba(0, 180, 102, 0.25);
}

.userResponsibilities .resp:hover {
    color: #00B466;
    cursor: pointer;
}

.userResponsibilities .selected {
    padding: 16px 32px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #00B466;
    background: #F8FFFC;
    border-bottom: 1px solid rgba(0, 180, 102, 0.25);
}

.logo {
    width: 50px;
    height: 50px;
    background: url("../src/icon_white.png");
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
}

.logo-dark {
    width: 100px;
    height: 100px;
    background: url("../src/icon_black.png");
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
}

.custom--List .ant-list-header {
    color: white;
    font-weight: 600;
    background-color: teal;
}

.custom--List .ant-list-item-meta-title {
    font-size: 1rem;
    color: black;
}

.mappingModal label {
    color: black;
    /* font-weight: 600; */
}

.mappingModal
label:not(.ant-checkbox-wrapper):not(.ant-radio-button-wrapper):not(.ant-radio-wrapper) {
    font-weight: 600;
}

.normalizedLabel {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
}

.customizedDrawer .ant-input {
    border: 1px solid black;
}

.customizedDrawer .custom--Checkbox .ant-checkbox-wrapper {
    font-size: 1rem;
}

.ant-layout-header {
    padding: 0px 5px !important;
}

/* Color codes */
.patient_status-mild {
    background-color: #83f52c !important;
    color: black !important;
}

.patient_status-moderate {
    background-color: #ffa15c !important;
    color: black !important;
}

.patient_status-severe {
    background-color: #ff3d37 !important;
    color: white !important;
}

.patient_status-normal {
    background-color: #a9a9a9 !important;
    color: black !important;
}

/* .moderate {
  background-color: #FFA15C !important;
  color: black !important;
}
.severe {
  background-color: #FF3D37 !important;
  color: white !important;
}
.normal {
  background-color: #a9a9a9 !important;
  color: black !important;
} */

/* Medicine interaction */
/* .interactionExists .ant-select-selector{
  border: 1px solid red !important;
} */
.subscription--stats .ant-statistic-title {
    color: #000;
}

.subscription--stats .ant-statistic-content {
    color: #000;
    font-weight: 700;
    font-size: 2rem;
}

.ptList_phone {
    font-size: 1rem;
    color: #1890ff;
}

#diagnoses-list .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
}

.ant-timeline-item {
    padding-bottom: 60px !important;
}

.ant-timeline-item-tail {
    border-left: 2px solid lightgray !important;
}

.statusTag {
    padding: 0.2rem 0.5rem;
    color: aquamarine;
    border: 1px;
    border-radius: 50%;
}

.statusTag--done {
    padding: 0.2rem 0.5rem;
    color: lightgreen;
    border: 1px;
    border-radius: 50%;
}

.statusTag--wrapper {
    margin-bottom: 4px;
}

.statusTag--wrapper > span {
    margin: 0rem 0.2rem;
}

.patientView--section {
    /* margin-bottom: 16px; */
    padding: 0.4rem 1rem;
}

.patientView--section.active {
    /* margin-bottom: 16px; */
    background: cornflowerblue;
    padding: 0.4rem 1rem;
    color: #fafafa;
}

.patientView--section:hover {
    cursor: pointer;
}

#dynamicVitals > .ant-col-12 {
    padding: 1rem 0rem;
}

/* New design */
.button--primary {
    background: #00b466 !important;
    color: #ffffff !important;
    /* width: 160px !important; */
    /* height: 50px !important; */
    border: 1px solid #00b466 !important;
    border-radius: 4px !important;
    /* padding: 15px 20px !important; */
    font-size: 16px !important;
    font-weight: 500 !important;
    cursor: pointer;
}

.button--secondary {
    background: #ffffff !important;
    color: #00b466 !important;
    /* width: 164px !important; */
    /* height: 50px !important; */
    border: 1px solid #00b466 !important;
    border-radius: 4px !important;
    /* padding: 15px 20px !important; */
    font-size: 16px !important;
    font-weight: 500 !important;
}

.h40 {
    height: 40px !important;
}

.tblue {
    color: #007aff !important;
}

.tmedium {
    font-weight: 500 !important;
}

.site-form-item-icon {
    margin-right: 20px;
}

/* ANT MODAL STYLES */
.ant-modal-header {
    padding: 0 !important;
}

/* ANT INPUT STYLES */
.input--backgroundGray {
    background-color: #fafafa !important;
}

/* ANT SELECT STYLES */
.select--h48 > .ant-select-selector {
    height: 48px !important;
}

.select--h40 > .ant-select-selector {
    height: 40px !important;
}

.h40 input,
.h40 .ant-radio-button-wrapper {
    height: 40px !important;
    line-height: 40px;
}

.radiogroup--padding49 .ant-radio-button-wrapper {
    padding: 0 49px;
}

.tgreen .ant-select-selector {
    color: #00b466 !important;
}

.select--noBorder .ant-select-selector {
    border: none !important;
}

.select--greenChevron svg {
    color: #00b466 !important;
}

/* ANT SELECT STYLES */

/* ANT DATE, TIME PICKER STYLE */
.ant-picker {
    height: 48px;
}

.ant-picker svg {
    color: #007aff;
}

/* ANT DATE, TIME PICKER STYLE */

/* CENTER TEXT VERTICALLY */
.select--h48 .ant-select-selection-item {
    line-height: 48px !important;
}

/* FONT WEIGHT: 500 FOR ANT SELECT */
.tmedium .ant-select-selection-item {
    font-weight: 500;
}

/* FONT WEIGHT: 500 FOR ANT INPUT PLACEHOLDER */
.tmedium .ant-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-weight: 500;
}

.tmedium .ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 500;
}

.tmedium .ant-input::-ms-input-placeholder {
    /* Microsoft Edge */
    font-weight: 500;
}

/* FONT WEIGHT: 500 FOR ANT INPUT PLACEHOLDER */

/* DEFAULT COLOR FOR ANT INPUT PLACEHOLDER */
.ant-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #979797 !important;
    opacity: 1; /* Firefox */
}

.ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #979797 !important;
}

.ant-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #979797 !important;
}

/* DEFAULT COLOR FOR ANT INPUT PLACEHOLDER */

.anticon {
    color: #979797 !important;
}

/* OVERRIDING AUTOFILL BACKGROUND COLOR */
/* :autofill DOCS: https://developer.mozilla.org/en-US/docs/Web/CSS/:autofill */
/* CODE SOURCE: https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/* MAKE QUEUE TABLE ROWS CLICKABLE  */
.table--clickableRow,
.table--clickableRow .ant-tag {
    cursor: pointer;
}

/* ADD BOTTOM BORDER TO TABLE ROWS  */
.ant-table-row:not(:last-child) .ant-table-cell {
    border-bottom: 1px solid #e2e9f4;
}

/* TABLE COLUMN NAMES FONT WEIGHT */
.ant-table-thead > tr > th,
.ant-table-cell {
    font-weight: 400 !important;
}

/* TABLE BODY FONT SIZE */
tbody .ant-table-cell {
    font-size: 16px;
}

/* HACK TO EMULATE SPACING BETWEEN TABLE HEADER AND TABLE BODY */
.ant-table-thead > tr > th,
.ant-table-cell {
    background-color: white !important;
}

.ant-table {
    background-color: #f0f2f5 !important;
}

.ant-table tbody::before {
    content: "";
    display: block;
    height: 5px;
}

/* HACK TO EMULATE SPACING BETWEEN TABLE HEADER AND TABLE BODY */

/* TABLE ROW CELLS PADDING */
tbody .ant-table-cell {
    padding: 30px !important;
}

/* TABLE HEAD CELLS PADDING */
thead .ant-table-cell {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

/* FONT SIZE FOR STATUS TAGS IN QUEUE PAGE */
.ant-tag {
    font-size: 14px !important;
}

/* PAGE PADDING */
.ant-layout-content {
    padding: 24px;
}

.antd--form--hideRequired .ant-form-item-label > label.ant-form-item-required::before {
    content: ""
}

/* QUEUE SAMPLE DATA */
/* [
    {
        "id": 257225,
        "person_id": 64818,
        "token": "1",
        "vitals": "1",
        "symptoms": "0",
        "history": "0",
        "diagnosis": "0",
        "investigation": "0",
        "billing": "0",
        "prescription": "0",
        "is_completed": 0,
        "is_review": 0,
        "is_call": false,
        "sessions_id": 188,
        "clinic_id": 5,
        "critical_on": null,
        "ap_date": "2021-09-27",
        "physical_exam": "0",
        "print_pdf": "0",
        "complaint": 0,
        "is_paid": 0,
        "position": 1,
        "apt_legacy_id": null,
        "ap_type": "app",
        "weight": null,
        "ap_time": "07:30 PM",
        "booked_time": "2021-09-27 19:30:00",
        "doctor_id": 28,
        "name": "Baby. TEST TEST ",
        "allergy": "None stated by patient",
        "doctor_reference": "",
        "descriptive_notes": "",
        "age": "15-04-2019",
        "gender": "Other",
        "place": "",
        "doctor_name": "Dr. Gokul",
        "phone_number_id": 64897,
        "phone_number": "7079769769",
        "emergency_id": 17150,
        "emergency_name": "",
        "emergency_number": "",
        "image_id": null,
        "previous_appointment": "26 Sep 2021 ",
        "profile_status": "Complete",
        "app_datee": "2021-09-27",
        "image_new": null
    }
] */


/* Date picker in Vitals */
.vitals-datepicker {
    height: 43px !important;
    width: 100%;
    padding: 0 12px !important;
    border: 1px solid #E2E9F4 !important;
    border-radius: 2px !important;
}

.vitals-datepicker input {
    font-size: 26px !important;
    color: #574B61 !important;
    width: 100% !important;
}


/* Age Popconfirm */
.age-popconfirm .ant-popover-buttons {
    padding: 0 16px 16px 16px;
}

.age-popconfirm .ant-popover-message-title,
.age-popconfirm .ant-popover-message,
.age-popconfirm .ant-popover-inner-content {
    padding: 0;
}


/* Removing Hidden input from Ant Form */
.hidden-form-item .ant-form-item-control-input {
    min-height: 2px;
}
